<template>
	<Utils ref='Utils'></Utils>
	<div class="surface-ground card" style='justify-content: center;'>
		<div style='margin-top:1.875rem;' class="text-900 font-bold text-6xl mb-4 text-center">Pricing Plans</div>
		<div class="text-700 text-xl mb-3 text-center line-height-1">Please insure my code is what you want. Any payment
			can't be returned.</div>
		<div style="display: flex;flex-direction: column; justify-content: center;"
			class="text-700 text-xl mb-3 text-center line-height-1">
			<div style="margin-bottom: 1.25rem;">Any question welcome contact me via</div>
			<div title="click to contact me">
				<a target="_blank" :href="author.facebook"><Button icon="pi pi-facebook"
						class="p-button-rounded mr-2 mb-2" /></a>
				<a target="_blank" :href="author.twitter"><Button icon="pi pi-twitter"
						class="p-button-rounded p-button-info mr-2 mb-2" /></a>
				<Button icon="pi pi-whatsapp" @click="toggleWhatsApp"
					class="p-button-rounded p-button-success mr-2 mb-2"></Button>
				<OverlayPanel ref="whats_app" appendTo="body" :showCloseIcon="true">
					<img src="images/my_whatsapp.jpg" style="width: 300px;" />
				</OverlayPanel>
				<Button icon="pi pi-weixin" @click="toggle" class="p-button-rounded p-button-success mr-2 mb-2"><i
						class="fa fa-weixin" aria-hidden="true"></i></Button>
				<OverlayPanel ref="op" appendTo="body" :showCloseIcon="true">
					<img src="images/my_wexin.jpg" style="width: 300px;" />
				</OverlayPanel>
				<Button style="padding: 12px;" @click='copyEmail()' label="weichaoxu1998@gmail.com" icon="pi pi-envelop"
					class="p-button-rounded p-button-warning mr-2 mb-2"><i class="fa fa-envelope"
						aria-hidden="true"><span
							style="margin-left: 5px;font-weight: 700;">weichaoxu1998@gmail.com</span></i></Button>
			</div>
		</div>
		<div class="grid">
			<div class="col-12 lg:col-3" v-for="(item, i) of pricing_plans" :key="i">
				<div class="p-3 h-full">
					<div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
						<div class="text-900 font-medium text-xl mb-2">{{item.level}}</div>
						<!-- <div class="text-600">{{item.description}}</div> -->
						<hr class="my-3 mx-0 border-top-1 border-none surface-border" />
						<div class="flex align-items-center">
							<span class="font-bold text-2xl text-900">{{item.currency}}{{item.price}}</span>
							<span class="ml-2 font-medium text-600">{{item.time}}</span>
						</div>
						<hr class="my-3 mx-0 border-top-1 border-none surface-border" />
						<ul class="list-none p-0 m-0 flex-grow-1">
							<li v-for="(fuc,j) of item.functions" :key="j" class="flex align-items-center mb-3">
								<i class="pi pi-check-circle text-green-500 mr-2"></i>
								<span>{{fuc}}</span>
							</li>
						</ul>
						<hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
						<div>
							<Button @click='selectPayWay(item,"paypal")' title='pay me with PayPal' label="Buy Now"
								icon="pi pi-paypal" style="width: 55%;float: left;"></Button>
							<Button @click='selectPayWay(item,"wechat")' style="width: 40%;float: right;"
								title='中国大陆地区微信支付' label="微信支付" class="p-button-success mr-2 mb-2"></Button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Dialog v-model:visible="show_buy_part" :style="{width: '450px'}" header="Input your domain and select month"
			:modal="true">
			<div class='grid col-12' style="margin-top: 10px;">
				<div class='col-3'>
					<span style="font-size: 1.125rem;font-weight: 700;">Domain</span>
				</div>
				<div class="col-9 p-inputgroup">
					<InputText title='set domain, can be changed' type="text" v-model='input_domain'
						placeholder="input your domain" />
					<!-- 					<span title="close" class="p-inputgroup-addon" @click='clearDomain'><i class="pi pi-times"
							aria-hidden="true"></i></span> -->
				</div>
			</div>
			<div class='grid col-12' style="margin-top: 10px;">
				<div class='col-3'>
					<span style="font-size: 1.125rem;font-weight: 700;">Month</span>
				</div>
				<div class='col-9' style="display: flex;flex-direction: row;">
					<Dropdown @update:modelValue="changeMonth" style='width: 100%;' v-model="month_value"
						:options="month_values" optionLabel="name" placeholder="Select month" />
				</div>
			</div>
			<div class='grid col-12'>
				<div class='col-3'>
					<span style="font-size: 1.125rem;font-weight: 700;">Description</span>
				</div>
				<div class='col-9' style="color: #999999;">
					After paied, key can be used for<span style='color:#ff6000'>【{{input_domain}}】</span> in <span
						style='color:#ff6000'>【{{month_value.name}}*30 days】</span>
				</div>
			</div>
			<div class='grid col-12'>
				<div class='col-3'>
				</div>
				<div class='col-5'>
					<span>Total fee：<span style="color:#ff6000;font-size: 18px;">{{select_plan.currency}}{{total_price}}</span></span>
				</div>
				<div class='col-4'>
					<Button label="Pay Now" style="margin-left: 20px;" class="p-button-success" @click='getPayQrcode' />
				</div>
			</div>
		</Dialog>
	</div>
</template>

<script>
	import Utils from './Utils.vue';
	export default {
		name: 'pricing',
		components: {
			"Utils": Utils,
		},
		data() {
			return {
				input_domain: 'demos.libertynlp.com',
				show_month: false,
				show_email: false,
				total_price: 0,
				pay_price: 0,
				select_space_id: 0,
				select_time_id: 0,
				month_values: [],
				month_value: {
					"name": 6,
					"code": 6,
				},
				space_bags: [{
						'name': '500M',
						'size': 500,
						'price': 9,
					},
					{
						'name': '1GB',
						'size': 1000,
						'price': 15,
					},
					{
						'name': '2GB',
						'size': 2000,
						'price': 25,
					},
					{
						'name': '4GB',
						'size': 4000,
						'price': 40,
					}
				],
				time_bags: [{
						'name': '1',
						'enlarge': 1,
					},
					{
						'name': '6',
						'enlarge': 6,
					},
					{
						'name': '12',
						'enlarge': 12,
					},
					{
						'name': '24',
						'enlarge': 24,
					}
				],
				show_buy_part: false,
				author: {
					'avatar': 'images/avatar/weichaoxu.jpg',
					'name': 'Chaoxu Wei',
					'title': 'Student at Tongji University',
					'work': 'Developer && Blogger',
					'content': 'Job hunting, both part-time and full-time job are accepted.',
					'twitter': 'https://twitter.com/Joker75342012?t=lvUwjAYZEp_3vRSNOmL0XA&s=09',
					'facebook': 'https://www.facebook.com/profile.php?id=100083516062282',
					'gmail': 'weichaoxu1998@gmail.com',
				},
				select_plan: {
						"level": 'Basic key',
						"currency": "$",
						"price": 10,
						"original_price": 20,
						"time": "per month per domain",
						"description": "plan description",
						"functions": [
							"all annotations fucntion",
							"api for read file annotations",
							"api for set file annotations",
						]
					},
				pricing_plans: [{
						"level": 'Basic key',
						"currency": "$",
						"price": 10,
						"original_price": 20,
						"time": "per month per domain",
						"description": "plan description",
						"functions": [
							"all annotations fucntion",
							"api for read file annotations",
							"api for set file annotations",
						]
					},
					{
						"level": 'Premium key',
						"currency": "$",
						"price": 30,
						"original_price": 60,
						"time": "per month per domain",
						"description": "plan description",
						"functions": [
							"all annotations fucntion",
							"api for read file annotations",
							"api for set file annotations",
							"api for highlight/underline call back",
							"api for add rectangle/text/arrow/circle call back",
							"api for add slection call back",
							"api for add download call back",
						]
					},
					{
						"level": 'Enterprise encrpted code',
						"currency": "$",
						"price": 1000,
						"original_price": 1200,
						"time": "life time",
						"description": "plan description",
						"functions": [
							"all annotations fucntion",
							"all api",
							"life time key",
							"no limit for domain",
							"encrpted code",
						]
					},
					{
						"level": 'Enterprise source code',
						"currency": "$",
						"price": 2000,
						"original_price": 2500,
						"time": "life time",
						"description": "plan description",
						"functions": [
							"source code"
						]
					}
				],
				checked: false
			}
		},
		created() {
			this.createMonth();
		},
		methods: {
			createMonth() {
				var new_month_value = [];
				for (var i = 1; i < 101; i++) {
					var tmp = {
						"name": i,
						"code": i,
					};
					new_month_value.push(tmp);
				}
				this.month_values = new_month_value;
				this.total_price = this.month_value.name;
			},
			toggle(event) {
				this.$refs.op.toggle(event);
			},
			toggleWhatsApp(event) {
				this.$refs.whats_app.toggle(event);
			},
			copyEmail() {
				this.$refs.Utils.copyText(this.author.gmail);
			},
			selectPayWay(item, pay_way) {
				this.show_buy_part = true;
				this.select_plan=item;
				this.countTotalPrice(this.month_value.name,this.select_plan.price);
				console.log(pay_way);
			},
			countTotalPrice(time,price){
				this.total_price=time*price;
			},
			changeMonth(value) {
				this.countTotalPrice(value.name,this.select_plan.price);
			},
		}
	}
</script>

<style scoped lang="scss">
	.percentage-container {
		// margin-top: -40px;
		font-size: 20px;
		height: 50px;
		padding: 0;
		transform: scale(0.5, 0.5);
		margin-right: -30px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		// background-color: red;
	}

	.manu-refresh {
		color: #ffffff;
		font-size: 10px;
		text-decoration: underline;
	}

	.manu-refresh:hover {
		font-weight: 600;
		color: #ffffff;
	}

	.center-div {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	//微信支付颜色#00b034
	.buy-button {
		background-color: #515e88;
		color: #ffffff;
		border: none;
		border-radius: 0;
	}

	.no-buy-button {
		background-color: #edeef3;
		color: #000000;
		border: none;
		border-radius: 0;
	}

	.translation-item {
		display: flex;
		align-items: center;
		border-top: 1px solid #ededed;
		padding: 2px 0px 2px 0px;
	}

	.paper-name {}

	.paper-name:hover {
		color: #1fa1fc;
		font-weight: 600;
	}

	.word-item {}

	.word-item:hover {
		background-color: #1fa1fc;
		color: #ffffff;
	}

	.table-header {
		display: flex;
		justify-content: space-between;
	}

	.product-image {
		width: 100px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	}

	.p-dialog .product-image {
		width: 150px;
		margin: 0 auto 2rem auto;
		display: block;
	}

	.confirmation-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.product-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-taged {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-untag {
			background: #FFCDD2;
			color: #C63737;
		}

		&.status-lowstock {
			background: #FEEDAF;
			color: #8A5340;
		}
	}

	::v-deep(.p-toolbar) {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
	}
</style>
